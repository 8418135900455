@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,600;1,6..12,600');
.gridStyle {
	background: #336473;
}
.TypographyHeaderStyle {
	/* Create a new third p */

	height: 54px;
	left: 0%;
	right: 23.19%;
	top: calc(50% - 54px / 2 - 30px);

	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	font-size: 40px;
	line-height: 54px;

	/* White */

	color: #ffffff;
}
.LabelStyle {
	/* Help Text */

	position: absolute;
	width: 180px;
	height: 24px;
	right: 0px;
	top: 0px;

	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	/* identical to box height, or 171% */
	background-color: white;
}
.TextBoxStyle {
	/* Form Field Copy */

	box-sizing: border-box;

	position: absolute;
	height: 40px;
	left: 0%;
	right: 0%;
	top: 29px;

	background: #ffffff;
	border: 1px solid #053747;
	border-radius: 3px;
}
.App-content {
	min-height: calc(150vh - (156px + 120px));

	background: rgb(192, 205, 209);

	background: linear-gradient(
		180deg,
		rgba(192, 205, 209, 1) 0%,

		rgba(230, 235, 237, 0) 80%
	);
}

.ButtonStyle {
	/* #1DAAF1 */

	position: absolute;
	left: 0%;
	right: 0%;
	top: 0%;
	bottom: 0%;
	float: right;

	background: #053747;
}

body {
	font-family: 'Open Sans', Arial;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
